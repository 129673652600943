import { Component } from '@angular/core';
import { User } from 'src/app/model/user';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public user:User | undefined;
  constructor(private authorizationService:AuthorizationService){
    this.user = this.authorizationService.getCurrentUser();
  }
}
