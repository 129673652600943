export interface TableData{
    displayedColumns:Array<Column>;
    records:Array<any>
    totalRecords:number;
}
export interface Column{
    fieldName:string;
    type:ColumnType;
}
export enum ColumnType{
    Number = "number",
    Text = "text",
    Url = "url"
}