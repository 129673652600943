import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';
import { jwtDecode } from "jwt-decode";

/**
 * @description
 * This service controls the user authentication process, using @see AdalService.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {

  constructor(
    private oauthService: OAuthService,
    private router: Router
  ) {
    this.oauthService.configure({
      ...environment.adalConfig,
      redirectUri: window.location.origin + '/postauth',
    });
    this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
  }

  public isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public async login(): Promise<boolean> {
    if (this.isLoggedIn()) {
      this.router.navigate(['postauth']);
      return true;
    }
    else {
      const isLoggedIn = await this.oauthService.loadDiscoveryDocumentAndTryLogin();
      if (isLoggedIn) {
        return true;
      }
      else {
        this.oauthService.initImplicitFlow();
        return isLoggedIn; //not reachable - flow should redirect to login page
      }
    }
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  public getToken(): string {
    return this.oauthService.getAccessToken();
  }

  public getCurrentUser(): User {
    let token = this.getToken();
    let decodedToken = jwtDecode<User>(token);
    let user: User = {
      given_name: decodedToken.given_name,
      family_name: decodedToken.family_name,
      name: decodedToken.name,
      upn: decodedToken.upn
    }
    return user;
  }
}
