<app-header></app-header>
<app-sub-header [navigateParams]="navigateParams"></app-sub-header>
<div class=" container-fluid mt--6">
    <div class=" row">
        <div class=" col">
            <ng-container *ngIf="stateData$ | async as stateData">
                <ng-container *ngIf="stateData.isLoading">Loading...</ng-container>

                <ng-container *ngIf="stateData.data as data">
                    <app-table [listData]="listData" [tableTitle]="tableTitle" (openUrlEvent)="openUrlEvent($event)"></app-table>
                </ng-container>
            </ng-container>

        </div>
    </div>
</div>