import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, take } from 'rxjs';
import { HttpService } from './http.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoadableState } from '../model/loadable-state';
import { RedshiftData } from '../model/redshift-data';

/**
 * This service controls the navigation between divisions and fetching their graphs data.
 */
@Injectable({
  providedIn: 'root'
})
export class DataService {
  private hubbyData: BehaviorSubject<LoadableState<RedshiftData> | null> = new BehaviorSubject<LoadableState<RedshiftData> | null>(null);
  public hubbyData$: Observable<LoadableState<RedshiftData> | null> = this.hubbyData.asObservable();
  public imgUrl:Subject<string> = new Subject<string>();
  public imgUrl$:Observable<string> = this.imgUrl.asObservable();

  constructor(private httpService: HttpService, private activatedRoute: ActivatedRoute, private router: Router) {
  }

  public getHubbyData(): void {
    this.hubbyData.next({
      isLoading: true
    });
    this.httpService.get<RedshiftData>('data/hubby-data').pipe(take(1)).subscribe(data => {
      this.hubbyData.next({
          isLoading: false,
          data: data
        });
    })
  }
  getImageByUrl(url: { url: string; }){
    this.httpService.post('data/img',url).subscribe((data:any)=>{
      this.imgUrl.next(data.url as string);
    })
  }
}
