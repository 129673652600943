import { Observable, Subject, distinctUntilChanged, fromEvent, map, pairwise, takeUntil, throttleTime } from 'rxjs';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { LoadableState } from 'src/app/model/loadable-state';
import { RedshiftColumnMetadata, RedshiftData } from 'src/app/model/redshift-data';
import { ColumnType, TableData } from 'src/app/model/table-data';
import { NavigateParams } from 'src/app/model/navigate-params';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public stateData$!: Observable<LoadableState<RedshiftData> | null>;
  public listData: TableData = { displayedColumns: [], records: [], totalRecords: 0 };
  public tableTitle: string = "Hubby Data";
  public navigateParams: NavigateParams = { main: "Dashboard", subMain: "Data", sub: "Hubby Data" }
  constructor(private dataService: DataService) {
    this.dataService.hubbyData$.subscribe((data: LoadableState<RedshiftData> | null) => {
      data && !data.isLoading ? this.setListData(data.data as RedshiftData) : "";
    })
  }

  ngOnInit(): void {
    this.stateData$ = this.dataService.hubbyData$;
    this.dataService.getHubbyData();
  }

  setListData(data: RedshiftData) {
    this.listData.displayedColumns =
      data.ColumnMetadata ?
        data.ColumnMetadata.map((column: RedshiftColumnMetadata) => {
          return {
            fieldName: column.name,
            type: column.name != 'url' ? ColumnType.Text : ColumnType.Url
          }
        }) : [];
    this.listData.records = data.Records ? data.Records : [];
    this.listData.totalRecords = data.TotalNumRows ? data.TotalNumRows : 0;
  }
  async openUrlEvent(imgUrl: string) {
    this.dataService.getImageByUrl({ url: imgUrl });
    this.dataService.imgUrl$.subscribe(url=>{
      Swal.fire<any>({
        imageUrl: url as string,
      });
    })
  }
}
