import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * @description
 * This service is used to handle the state of a HTTP request.
 * It starts every http request with a 'loading' state, until it gets a response from the service.
 * According to the response it will send the data in case of a sucessful request.
 * Otherwise, it will return a detailed 'error' state.
 */
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient) { }

  public get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(environment.baseUri + url);
  }

  public  post<T>(url:string, data?:any): Observable<T>{
    return this.httpClient.post<T>(environment.baseUri + url, data)
  }
}
