import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TableData } from 'src/app/model/table-data';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort, { static: false }) sort: MatSort | undefined;
  @Input() listData:TableData | undefined;
  @Input() tableTitle:string | undefined;
  @Output() openUrlEvent = new EventEmitter();
  public dataSource: any;
  public colNames: Array<string> | undefined;
  public currentPage: number = 0;
  public resultsPageSize:number = 10;
  public pageSizeOptions: any;
  
  constructor(){
    this.dataSource = new MatTableDataSource<any>(this.listData?.records);
    this.dataSource.paginator = this.paginator;
    this.colNames = this.listData?.displayedColumns.map(col=>col.fieldName);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["listData"]) {
      if(this.listData?.records && this.listData.records.length>0) {
        this.dataSource = new MatTableDataSource<any>(this.listData.records); 
        this.dataSource.paginator = this.paginator;
        this.colNames = this.listData?.displayedColumns.map(col=>col.fieldName);
        this.dataSource.paginator ?  this.dataSource.paginator.pageIndex = this.currentPage:'';
        this.dataSource.paginator ? this.dataSource.paginator.length = this.listData.totalRecords:'';
      }
    }
  }
  
  async pageChangedEvent(event:any){
    this.currentPage = event.pageIndex;
  }
  openUrl(url:string){
    this.openUrlEvent.emit(url);
  }
}
