<div class=" header header-dark bg-icl pb-6 content__title content__title--calendar">
    <div class=" container-fluid">
        <div class=" header-body">
            <div class=" row align-items-center py-4">
                <div class=" col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"> {{navigateParams?.main}} </h6>

                    <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
                        <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
                            <li class=" breadcrumb-item">
                                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
                            </li>

                            <li class=" breadcrumb-item">
                                <a href="javascript:void(0)">{{navigateParams?.subMain}}</a>
                            </li>

                            <li class=" breadcrumb-item active">
                                {{navigateParams?.sub}}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
