<div class="card">
  <div class="card-header border-0">
    <div class="row align-items-center">
      <h3 class="mb-0 col-lg-3 col-7">{{tableTitle}}</h3>
      <!-- <div class="navbar-search navbar-search-light form-inline mr-sm-3 " id="navbar-search-main">
          <div class="form-group mb-0">
            <div class="input-group input-group-alternative input-group-merge">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-search"></i></span>
              </div>
              <input class="form-control" placeholder="Search..." type="text" [(ngModel)]="searchValue"
                (keyup)="applyFilter($event)" />
            </div>
          </div>
          <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main"
            aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div> -->
    </div>
  </div>
  <div>
    <div class="table-responsive" *ngIf="listData && listData.records.length>0; else noData">
      <table class="table align-items-center table-flush" mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="displayedCol.fieldName"
          *ngFor="let displayedCol of listData.displayedColumns;let i=index">
          <th class="thead-light" scope="col" mat-header-cell *matHeaderCellDef>
            {{displayedCol.fieldName}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="text-muted" *ngIf="displayedCol.type=='text'">
              {{ element[i].stringValue }}
            </span>
            <div class="text-muted url-link" *ngIf="displayedCol.type=='url'">
              <a class="img-link" (click)="openUrl(element[i].stringValue)">{{element[i].stringValue }}</a>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="colNames"></tr>
        <tr mat-row *matRowDef="let row; columns: colNames;"></tr>
      </table>
      <mat-paginator [length]="listData.totalRecords" [pageIndex]="currentPage" [pageSize]="resultsPageSize"
        [pageSizeOptions]="pageSizeOptions" (page)="pageChangedEvent($event)"></mat-paginator>
    </div>
    <ng-template #noData>
      <mat-divider></mat-divider>

      <mat-label class="no-data-placeholder">
        No Search result found
      </mat-label>
      <mat-divider></mat-divider>
    </ng-template>
  </div>
</div>