export const environment = {
  production: false,
  baseUri: 'https://api.dp-poc.appsc.icldig.icl-group.com/',
  adalConfig: {
    clientId: 'd1a29b6b-e33e-4353-be5a-bdc284189b25',
    issuer: 'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
    strictDiscoveryDocumentValidation: false,
    responseType: 'id_token token',
    scope: 'd1a29b6b-e33e-4353-be5a-bdc284189b25/.default',
  },
  version: '1.0.0',
};
